import React, { useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  Divider,
  Center,
  VStack,
  Grid,
  GridItem,
  Badge,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { SearchIcon } from "@chakra-ui/icons";

import { getFilteredCards } from "./consts/homeCards";

import { ReactComponent as Logo } from "../../finstack.svg";

const Home = () => {
  let navigate = useNavigate();
  const [searchPhrase, setSearchPhrase] = useState("");

  const cards = useMemo(() => {
    return getFilteredCards(searchPhrase);
  }, [searchPhrase]);

  return (
    <main>
      <VStack padding="0 20px" textAlign="center" m="75px auto 0 auto">
        <Flex alignItems="center">
          <Logo style={{ width: "70px" }} />
          <Text fontSize="6xl" marginLeft="15px">
            <Text as="b" marginLeft="5px">
              fin
            </Text>
            stack
          </Text>
          <Text fontSize="6xl">
            {/* <Text as="b" marginLeft="10px"> */}
            {/* fin */}
            {/* </Text> */}
            {/* stack */}
          </Text>
        </Flex>
        <Text fontSize="2xl" marginTop={8}>
          Zbiór narzędzi wspomagających finansowe kalkulacje
        </Text>
      </VStack>
      <Center>
        <Divider
          maxW="200px"
          orientation="horizontal"
          marginTop="75px"
          color="#001219"
        />
      </Center>
      {/* <Center marginTop="75px" h="64px" padding="0 20px">
        <InputGroup maxW="750px" h="100%">
          <InputLeftElement
            pointerEvents="none"
            height="100%"
            width="60px"
            children={<SearchIcon color="gray.300" w="23px" h="23px" />}
          />
          <Input
            h="100%"
            value={searchPhrase}
            onChange={(event) => {
              setSearchPhrase(event.target.value);
            }}
            paddingLeft="60px"
            fontSize="20px"
            placeholder="Wyszukaj..."
          />
        </InputGroup>
      </Center> */}
      <Center marginTop="75px">
        {cards.length > 0 ? (
          <Grid
            maxW="900px"
            w="100%"
            p="10px"
            templateColumns="repeat(auto-fill, 280px)"
            justifyContent="center"
            alignItems="stretch"
            gap={4}
          >
            {cards.map((card) => (
              <GridItem w="100%" key={card.key}>
                <Flex
                  borderWidth="1px"
                  borderRadius="lg"
                  p="20px"
                  h="100%"
                  direction="column"
                >
                  <Box>
                    <Stack direction="row" spacing={1}>
                      {card.tags.map((tag) => {
                        return (
                          <Badge
                            key={tag.name}
                            borderRadius="full"
                            padding="5px 12px"
                            fontSize="10px"
                            colorScheme={tag.colorTheme}
                          >
                            {tag.name}
                          </Badge>
                        );
                      })}
                    </Stack>
                  </Box>
                  <Box flexGrow={1} m="15px 0">
                    <Text fontSize="2xl">{card.name}</Text>
                    <Text marginTop="10px">{card.description}</Text>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => {
                        navigate(card.path);
                      }}
                    >
                      Uruchom
                    </Button>
                  </Box>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>Brak wyników</div>
        )}
      </Center>
    </main>
  );
};

export default Home;
