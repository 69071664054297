import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { configure } from "axios-hooks";
import LRU from "lru-cache";
import Axios from "axios";

import "./index.css";

import { ChakraProvider } from "@chakra-ui/react";

import App from "./App";

const axios = Axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `http://localhost:3001/api`
      : `https://${process.env.REACT_APP_API_HOST || ""}finstack.pl/api`,
});
const cache = new LRU({ max: 20 });

configure({
  axios,
  cache,
  defaultOptions: {
    ssr: false,
    useCache: true,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from "./tools/reportWebVitals";
// reportWebVitals();
