import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import _ from "lodash";

import LoanForm from "./LoanForm";
import LoanInstallments from "./LoanInstallments";
import LoanSummary from "./LoanSummary";

import {
  VStack,
  Text,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
} from "@chakra-ui/react";

import { ReactComponent as Logo } from "../../finstack.svg";

const deafultFormConfig = {
  cashLoan: {
    defaultValues: {
      principal: 21000,
      interestRate: 8,
      period: 24,
      installmentType: "equal",
    },
    principal: {
      min: 100,
      max: 40000,
      step: 100,
    },
    interestRate: {
      min: 0,
      max: 25,
      step: 0.1,
    },
    period: {
      min: 1,
      max: 180,
      step: 1,
    },
    installmentType: "equal",
  },
  mortgageLoan: {
    defaultValues: {
      principal: 340000,
      interestRate: 5,
      period: 360,
      installmentType: "equal",
    },
    principal: {
      min: 50000,
      max: 700000,
      step: 1000,
    },
    interestRate: {
      min: 0,
      max: 15,
      step: 0.1,
    },
    period: {
      min: 1,
      max: 420,
      step: 1,
    },
    installmentType: "equal",
  },
};

const CashLoan = () => {
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [formConfig, changeFormConfig] = useState(
    deafultFormConfig.mortgageLoan
  );
  const [formData, changeFormData] = useState(formConfig.defaultValues);
  const [lastSuccededFormData, changeLastSuccededFormData] = useState(formData);

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: "/cash-loan/calculator?allInstallments=true",
      method: "POST",
    },
    { manual: true }
  );

  const calculateLoanInstallment = (data) => {
    execute(
      { data: { ...data, interestRate: data.interestRate / 100 } },
      { useCache: true }
    ).then(() => {
      changeLastSuccededFormData(data);
    });
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };

  const onLoanTypeChange = (event) => {
    const newFormConfig = event.target.checked
      ? deafultFormConfig.mortgageLoan
      : deafultFormConfig.cashLoan;

    if (_.isEqual(formData, formConfig.defaultValues)) {
      changeFormData(newFormConfig.defaultValues);
    }

    changeFormConfig(newFormConfig);
  };

  const resetForm = () => {
    changeFormData(formConfig.defaultValues);
  };

  useEffect(() => {
    if (!!error && !errorSnackbarOpen) {
      setErrorSnackbarOpen(true);
    }

    if (!error && errorSnackbarOpen) {
      setErrorSnackbarOpen(false);
    }
  }, [error]);

  const displayError = !!error;
  const displayDiffWarning = !_.isEqual(lastSuccededFormData, formData);

  const ONE = 1;

  return (
    <main>
      {/* <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={15000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Nie udało się pobrać danych. Spróbuj ponownie.
        </Alert>
      </Snackbar> */}
      <VStack
        padding="0 20px"
        m="75px auto 0 auto"
        textAlign="center"
        maxW="900px"
      >
        <Flex alignItems="center">
          <Logo style={{ width: "65px", fill: "red" }} />
          <Text fontSize="6xl" marginLeft="15px">
            kredyt gotówkowy
          </Text>
        </Flex>

        <Text fontSize="2xl" marginTop={8}>
          Oblicz ratę kredytu gotówkowego. Sprawdź strukturę raty oraz zobacz
          jaka będzie całkowita wysokość odsetek
        </Text>
      </VStack>
      <Center>
        <Divider maxW="200px" orientation="horizontal" marginTop="75px" />
      </Center>
      <Center marginTop="75px">
        <LoanForm
          loading={loading}
          onSubmit={calculateLoanInstallment}
          formConfig={formConfig}
          formData={formData}
          changeFormData={changeFormData}
          resetForm={resetForm}
        />
      </Center>
      {!!data ? (
        <>
          <Center marginTop="25px">
            <TableContainer>
              <Table variant="striped" colorScheme="teal">
                <TableCaption>
                  Imperial to metric conversion factors
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Rata</Th>
                    <Th>Kapitał</Th>
                    <Th>Odsetki</Th>
                    <Th>Pozostało</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.installments.map((i) => {
                    return (
                      <Tr key={i.number}>
                        <Td>{i.number + 1}</Td>
                        <Td>
                          {i.installment.toLocaleString("pl-PL", {
                            style: "currency",
                            currency: "PLN",
                          })}
                        </Td>
                        <Td>
                          {i.principalMonth.toLocaleString("pl-PL", {
                            style: "currency",
                            currency: "PLN",
                          })}
                        </Td>
                        <Td>
                          {i.interestMonth.toLocaleString("pl-PL", {
                            style: "currency",
                            currency: "PLN",
                          })}
                        </Td>
                        <Td>
                          {i.principalLeft.toLocaleString("pl-PL", {
                            style: "currency",
                            currency: "PLN",
                          })}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Center>
        </>
      ) : null}
    </main>
  );
};

export default CashLoan;
