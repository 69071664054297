import React, { useCallback, useState } from "react";

import "./App.css";

import Home from "./modules/home/Home";
import Loan from "./modules/loan/Loan";
import LoanOverpayment from "./modules/loanOverpayment/LoanOverpayment";
// import MortgageSimulator from "./modules/mortgage/MortgageSimulator";

import SettingsContext, { initialSettings } from "./context/settingsContext";

import {
  Box,
  Button,
  Link,
  Flex,
  useColorMode,
  useColorModeValue,
  Center,
  Text,
  Icon,
  Menu,
  MenuButton,
  IconButton,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
} from "@chakra-ui/react";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { MoonIcon, SunIcon, HamburgerIcon } from "@chakra-ui/icons";

import { Routes, Route, Link as ReachLink } from "react-router-dom";

import { ReactComponent as Logo } from "./finstack.svg";

ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

function App() {
  const [settings, setSettings] = useState(initialSettings);

  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box bg={useColorModeValue("gray.700", "gray.900")}>
        <Flex
          h="64px"
          w="100%"
          maxW="950px"
          p="0 50px"
          m="0 auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link as={ReachLink} to="/">
            <Flex alignItems="center">
              <Icon as={Logo} width="25px" height="25px" />
              <Text color="gray.100" fontSize="20px">
                <Text as="b" marginLeft="5px">
                  fin
                </Text>
                stack
              </Text>
            </Flex>
          </Link>
          <Box>
            <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
            <Menu closeOnSelect={false}>
              <MenuButton
                ml="10px"
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
              />
              <MenuList>
                <MenuOptionGroup
                  defaultValue="on"
                  title="Podpowiedzi"
                  type="radio"
                  onChange={(v) => setSettings({ hints: v === "on" })}
                >
                  <MenuItemOption value="on">Włącz</MenuItemOption>
                  <MenuItemOption value="off">Wyłącz</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Box>
      <SettingsContext.Provider
        value={{
          settings,
          setSettings,
        }}
      >
        <Routes>
          <Route exact path="calculator/loan" element={<Loan />} />
          <Route
            exact
            path="calculator/loan-overpayment"
            element={<LoanOverpayment />}
          />

          {/* <Route
          exact
          path="simulator/mortgage"
          element={<MortgageSimulator />}
          /> */}
          <Route exact path="*" element={<Home />} />
        </Routes>
      </SettingsContext.Provider>
      <Box bg={useColorModeValue("gray.100", "gray.900")} marginTop="75px">
        <Center h="64px" maxW="950px" p="0 50px" m="0 auto">
          <Link as={ReachLink} to="/" m="0 5px">
            <Flex alignItems="center">
              <Icon as={Logo} width="20px" height="20px" />
              <Text as="b" marginLeft="5px">
                fin
              </Text>
              stack
            </Flex>
          </Link>
          <Text>2023</Text>
        </Center>
      </Box>
    </>
  );
}

export default App;
