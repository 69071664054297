import React, { useEffect, useState } from "react";
import _ from "lodash";

import NoData from "./components/NoData";
import DataDiffWarningIcon from "./components/DataDiffWarningIcon";
import DataLoadErrorIcon from "./components/DataLoadErrorIcon";

import { Doughnut } from "react-chartjs-2";

import {
  Grid,
  Button,
  GridItem,
  Text,
  RadioGroup,
  Radio,
  TabPanels,
  TabPanel,
  Tabs,
  Tab,
  TabList,
  Alert,
  AlertIcon,
  AlertDescription,
  Heading,
  Center,
  Tooltip,
} from "@chakra-ui/react";

import { InfoOutlineIcon } from "@chakra-ui/icons";

const defaultNoDataProps = {
  installments: [
    {
      installment: 1500,
      principalMonth: 1000,
      interestMonth: 500,
    },
    {
      installment: 1500,
      principalMonth: 500,
      interestMonth: 1000,
    },
  ],
};

const CashLoanInstallments = ({
  noData,
  installments,
  roundError,
  error,
  warning,
  showHints,
}) => {
  const [selectedInstallmentIndex, changeSelectedInstallmentIndex] =
    useState(0);

  if (noData) {
    installments = defaultNoDataProps.installments;
  }

  useEffect(() => {
    changeSelectedInstallmentIndex(0);
  }, [JSON.stringify(installments)]);

  const handleSelectedInstallmentIndex = (newValue) => {
    changeSelectedInstallmentIndex(newValue);
  };

  const displayedData = installments[selectedInstallmentIndex] || {};
  const isLastInstallment =
    installments.length - 1 === selectedInstallmentIndex;

  return (
    <Grid
      maxW="1000px"
      minH="500px"
      w="100%"
      justifyContent="center"
      boxShadow="lg"
      p="20px 25px"
      m="0 50px"
      borderRadius="8px"
      border="1px solid #e7e7e9"
      templateColumns="repeat(2, 1fr)"
      templateRows="fit-content(100%) 1fr"
      gap={4}
    >
      <GridItem colSpan={2}>
        {showHints ? (
          <Alert status="info" alignItems="flex-start" borderRadius="8px">
            <AlertIcon />
            <AlertDescription>
              Zobacz jaka jest wysokość oraz struktura Twojej raty. Przełączaj
              się pomiędzy zakładkami, aby zobaczyć strukturę wybranych rat.
            </AlertDescription>
          </Alert>
        ) : null}
      </GridItem>
      <GridItem>
        <Center axis="both" h="100%">
          <div>
            <Heading as="h3" size="md">
              Wysokość raty
            </Heading>
            <Tooltip
              isDisabled={!isLastInstallment}
              placement="right"
              mr="10px"
              hasArrow
              label={
                <div>
                  <p>{`Wartości poszczególnych rat są zaokrąglane, w wyniku czego postaje błąd zaokrąglenia. Może on zostać skorygowany w ostatniej racie.`}</p>
                  <p
                    style={{ marginTop: "5px" }}
                  >{`Błąd zaokrąglenia: ${roundError.toLocaleString("pl-PL", {
                    style: "currency",
                    currency: "PLN",
                  })}`}</p>
                  <p style={{ marginTop: "5px" }}>{`Rata: ${(
                    displayedData.installment + roundError
                  ).toLocaleString("pl-PL", {
                    style: "currency",
                    currency: "PLN",
                  })}`}</p>
                </div>
              }
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Text fontSize="md" color="gray">
                    Rata
                  </Text>
                  {isLastInstallment ? (
                    <InfoOutlineIcon color="#3182ce" ml="5px" w={5} h={5} />
                  ) : null}
                </div>

                {/* {warning ? <DataDiffWarningIcon /> : null} */}
                <Text mt="-15px" fontSize="5xl">
                  {displayedData.installment.toLocaleString("pl-PL", {
                    style: "currency",
                    currency: "PLN",
                  })}
                </Text>
              </div>
            </Tooltip>
            <Text mt="10px" fontSize="sm" color="gray">
              Rata kapitałowa
            </Text>
            <Text mt="-5px" fontSize="3xl">
              {displayedData.principalMonth.toLocaleString("pl-PL", {
                style: "currency",
                currency: "PLN",
              })}
            </Text>
            <Text mt="10px" fontSize="sm" color="gray">
              Rata odsetkowa
            </Text>
            <Text mt="-5px" fontSize="3xl">
              {displayedData.interestMonth.toLocaleString("pl-PL", {
                style: "currency",
                currency: "PLN",
              })}
            </Text>
          </div>
        </Center>
      </GridItem>
      <GridItem>
        <Center axis="both" h="100%" flexDirection="col">
          <div>
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              align="center"
              index={selectedInstallmentIndex}
              onChange={(index) => handleSelectedInstallmentIndex(index)}
            >
              <TabList>
                {(installments || []).map((i) => (
                  <Tab key={i.number}>{i.number + 1}. rata</Tab>
                ))}
              </TabList>
            </Tabs>

            <div
              style={{
                width: "275px",
                height: "275px",
                margin: "10px auto 0px auto",
              }}
            >
              <Doughnut
                data={{
                  labels: ["Rata kapitałowa", "Rata odsetkowa"],
                  datasets: [
                    {
                      // label: "Struktura raty",
                      data: [
                        displayedData.principalMonth,
                        displayedData.interestMonth,
                      ],
                      backgroundColor: ["#3182ce", "#E53E3E"],
                      borderRadius: 4,
                      borderWidth: 1,
                      spacing: 2,
                    },
                  ],
                }}
                options={{
                  cutout: "60%",
                  plugins: {
                    datalabels: {
                      formatter: (value) => {
                        let sum =
                          displayedData.principalMonth +
                          displayedData.interestMonth;
                        let percentage = ((value * 100) / sum).toFixed(2) + "%";
                        return percentage;
                      },
                      color: "#fff",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return context.parsed.toLocaleString("pl-PL", {
                            style: "currency",
                            currency: "PLN",
                          });
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </Center>
      </GridItem>
    </Grid>
  );
};

export default CashLoanInstallments;
