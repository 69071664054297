import { createContext } from "react";

const initialSettings = {
  hints: true,
};

const SettingsContext = createContext(null);

export default SettingsContext;

export { initialSettings };
