import React from "react";

import {
  Grid,
  Button,
  GridItem,
  Text,
  RadioGroup,
  Radio,
  Alert,
  AlertIcon,
  AlertDescription,
  Stack,
} from "@chakra-ui/react";

import Input from "../../components/Input";

const CashLoanForm = ({
  loading,
  onSubmit,
  formConfig,
  formData,
  changeFormData,
  resetForm,
}) => {
  const { principal, interestRate, period, installmentType } = formData;

  const calculate = () => {
    onSubmit({
      installmentType,
      principal,
      interestRate,
      period,
    });
  };

  const changeValue = (type) => (value) => {
    changeFormData({
      ...formData,
      [type]: value,
    });
  };

  return (
    <Grid
      maxW="1000px"
      minH="500px"
      w="100%"
      justifyContent="center"
      boxShadow="lg"
      p="20px 25px"
      m="0 50px"
      borderRadius="8px"
      border="1px solid #e7e7e9"
      gridAutoColumns="1fr"
      templateAreas={{
        base: `"alert"
                 "principal" 
                 "interest_rate" 
                 "period"
                 "installment_type"
                 "calculate_btn"
                 "reset_form_btn"`,
        sm: `"alert alert"
                 "principal principal" 
                 "interest_rate period"
                 "installment_type installment_type"
                 "reset_form_btn reset_form_btn" 
                 "calculate_btn calculate_btn"`,
        md: `"alert alert alert alert"
               "principal principal interest_rate interest_rate"
               "period period installment_type installment_type"
               "reset_form_btn calculate_btn calculate_btn calculate_btn"`,
      }}
      gap={4}
    >
      <GridItem gridArea="alert">
        <Alert status="info" alignItems="flex-start" borderRadius="8px">
          <AlertIcon />
          <AlertDescription>
            Ustaw parametry swojego kredytu, następnie kliknij przycisk{" "}
            <b>Oblicz</b> aby zobaczyć wysokość raty oraz koszt kredytu.
            Wartości graniczne na suwaku nalezy traktować jako informacyjne -
            pola tekstowe pozwalają na wpisanie własnej liczby.
          </AlertDescription>
        </Alert>
      </GridItem>
      <GridItem gridArea="principal">
        <>
          <Text fontSize="17px" fontWeight={700} marginBottom="5px">
            Kwota kredytu
          </Text>
          <Input
            adornment="zł"
            min={formConfig.principal.min}
            max={formConfig.principal.max}
            step={formConfig.principal.step}
            value={principal}
            onChange={changeValue("principal")}
            showButtons
            showSlider
          />
        </>
      </GridItem>
      <GridItem gridArea="interest_rate">
        <>
          <Text fontSize="17px" fontWeight={700} marginBottom="5px">
            Oprocentowanie
          </Text>
          <Input
            min={formConfig.interestRate.min}
            max={formConfig.interestRate.max}
            step={formConfig.interestRate.step}
            adornment="%"
            defaultValue={5}
            value={interestRate}
            onChange={changeValue("interestRate")}
            showButtons
            showSlider
          />
        </>
      </GridItem>
      <GridItem gridArea="period">
        <>
          <Text fontSize="17px" fontWeight={700} marginBottom="5px">
            Okres spłaty
          </Text>
          <Input
            min={formConfig.period.min}
            max={formConfig.period.max}
            step={formConfig.period.step}
            adornment="mies."
            defaultValue={360}
            value={period}
            onChange={changeValue("period")}
            showButtons
            showSlider
          />
        </>
      </GridItem>
      <GridItem gridArea="installment_type">
        <>
          <Text fontSize="17px" fontWeight={700} marginBottom="5px">
            Typ raty
          </Text>
          <RadioGroup
            value={installmentType}
            onChange={(value) => changeValue("installmentType")(value)}
          >
            <Stack direction="row" spacing={2}>
              <Radio value="equal">Równe</Radio>
              <Radio value="decreasing">Malejące</Radio>
            </Stack>
          </RadioGroup>
        </>
      </GridItem>
      <GridItem gridArea="reset_form_btn">
        <Button
          size="lg"
          colorScheme="blue"
          variant="ghost"
          w="100%"
          onClick={resetForm}
        >
          Resetuj
        </Button>
      </GridItem>
      <GridItem gridArea="calculate_btn">
        <Button
          isLoading={loading}
          loadingText="Oblicz"
          size="lg"
          colorScheme="blue"
          w="100%"
          onClick={calculate}
        >
          Oblicz
        </Button>
      </GridItem>
    </Grid>
  );
};

export default CashLoanForm;
