import React from "react";
import _ from "lodash";

import NoData from "./components/NoData";
import DataDiffWarningIcon from "./components/DataDiffWarningIcon";
import DataLoadErrorIcon from "./components/DataLoadErrorIcon";

import {
  Grid,
  Button,
  GridItem,
  Text,
  RadioGroup,
  Radio,
  Alert,
  AlertIcon,
  AlertDescription,
  Heading,
  Center,
} from "@chakra-ui/react";

import { Doughnut } from "react-chartjs-2";

const defaultNoDataProps = {
  interestTotal: 250000,
  principalTotal: 300000,
  totalCost: 550000,
};

const CashLoanSummary = ({
  noData,
  interestTotal,
  principalTotal,
  totalCost,
  error,
  warning,
  showHints,
}) => {
  if (noData) {
    interestTotal = defaultNoDataProps.interestTotal;
    principalTotal = defaultNoDataProps.principalTotal;
    totalCost = defaultNoDataProps.totalCost;
  }

  return (
    <Grid
      maxW="1000px"
      minH="500px"
      w="100%"
      justifyContent="center"
      boxShadow="lg"
      p="20px 25px"
      m="0 50px"
      borderRadius="8px"
      border="1px solid #e7e7e9"
      templateColumns="repeat(2, 1fr)"
      templateRows="fit-content(100%) 1fr"
      gap={4}
    >
      <GridItem colSpan={2}>
        {showHints ? (
          <Alert status="info" alignItems="flex-start" borderRadius="8px">
            <AlertIcon />
            <AlertDescription>
              Zobacz jaki jest końcowy koszt Twojego kredytu oraz wysokość
              odsetek jakie musisz zapłacić za użyczenie ustawionej kwoty.
            </AlertDescription>
          </Alert>
        ) : null}
      </GridItem>
      <GridItem display="flex">
        <div
          style={{
            width: "275px",
            height: "275px",
            margin: "auto",
          }}
        >
          <Doughnut
            data={{
              labels: ["Kapitał", "Odsetki"],
              datasets: [
                {
                  // label: "Struktura kredytu",
                  data: [interestTotal, principalTotal],
                  backgroundColor: ["#E53E3E", "#3182ce"],
                  borderRadius: 4,
                  borderWidth: 1,
                  spacing: 2,
                },
              ],
            }}
            options={{
              layout: {
                padding: 5,
              },
              cutout: "60%",
              plugins: {
                datalabels: {
                  formatter: (value) => {
                    let sum = principalTotal + interestTotal;
                    let percentage = ((value * 100) / sum).toFixed(2) + "%";
                    return percentage;
                  },
                  color: "#fff",
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return context.parsed.toLocaleString("pl-PL", {
                        style: "currency",
                        currency: "PLN",
                      });
                    },
                  },
                },
              },
            }}
          />
        </div>
      </GridItem>
      <GridItem>
        <Center axis="both" h="100%">
          <div>
            <Heading as="h3" size="md">
              Koszt kredytu
            </Heading>
            <Text mt="10px" fontSize="md" color="gray">
              Kwota do spłaty
            </Text>
            {/* {warning ? <DataDiffWarningIcon /> : null} */}
            <Text mt="-15px" fontSize="5xl">
              {totalCost.toLocaleString("pl-PL", {
                style: "currency",
                currency: "PLN",
              })}
            </Text>
            <Text mt="10px" fontSize="sm" color="gray">
              Kapitał
            </Text>
            <Text mt="-5px" fontSize="3xl">
              {principalTotal.toLocaleString("pl-PL", {
                style: "currency",
                currency: "PLN",
              })}
            </Text>
            <Text mt="10px" fontSize="sm" color="gray">
              Odsetki
            </Text>
            <Text mt="-5px" fontSize="3xl">
              {interestTotal.toLocaleString("pl-PL", {
                style: "currency",
                currency: "PLN",
              })}
            </Text>
          </div>
        </Center>
      </GridItem>
    </Grid>
  );
};

export default CashLoanSummary;
