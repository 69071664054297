import React from "react";
import _ from "lodash";

import {
  Text,
  Box,
  Input,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

const InputWithSlider = ({
  label,
  min = 0,
  max = 1000000,
  step = 5000,
  adornment = "",
  value,
  onChange,
  style,
  showButtons = false,
  showSlider = false,
}) => {
  return (
    <Box>
      <Text>{label}</Text>
      <Flex height="48px" border="1px solid #e7e7e9" borderRadius="8px">
        {showButtons ? (
          <Button
            borderRight="1px solid #e7e7e9"
            variant="ghost"
            height="100%"
            onClick={() => {
              const newValue = _.round(value - step, 2);

              onChange(newValue >= 0 ? newValue : 0);
            }}
          >
            <MinusIcon />
          </Button>
        ) : null}
        <Input
          value={value}
          onChange={(e) => {
            onChange(_.round(e.target.value, 2));
          }}
          height="100%"
          variant="unstyled"
          placeholder={label}
          type="number"
          textAlign="center"
        />
        {showButtons ? (
          <Button
            borderLeft="1px solid #e7e7e9"
            variant="ghost"
            height="100%"
            onClick={() => {
              const newValue = _.round(value + step, 2);

              onChange(newValue);
            }}
          >
            <AddIcon />
          </Button>
        ) : null}
      </Flex>
      {showSlider ? (
        <>
          <Slider
            focusThumbOnChange={false}
            value={value}
            onChange={(val) => {
              onChange(val);
            }}
            min={min}
            max={max}
            step={step}
            marginTop="10px"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Flex
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   position: "relative",
            //   top: -10,
            // }}
            justifyContent="space-between"
          >
            <Text
              // style={{
              //   fontWeight: "400",
              //   fontSize: "0.875rem",
              //   lineHeight: "1.43",
              //   letterSpacing: "0.01071em",
              //   color: "rgba(0, 0, 0, 0.87)",
              // }}
              fontSize="sm"
            >
              {min.toLocaleString()} {adornment}
            </Text>
            <Text
              fontSize="sm"
              // style={{
              //   fontWeight: "400",
              //   fontSize: "0.875rem",
              //   lineHeight: "1.43",
              //   letterSpacing: "0.01071em",
              //   color: "rgba(0, 0, 0, 0.87)",
              // }}
            >
              {max.toLocaleString()} {adornment}
            </Text>
          </Flex>
        </>
      ) : null}
    </Box>
  );
};

export default InputWithSlider;
